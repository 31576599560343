import { Box, Typography, useMediaQuery, useTheme,Button } from "@mui/material";
import React from "react";
import Hero from "./Hero";
import Social from "./Social";
import Tokenomics from "./Tokenomics";
import Footer from "./Footer";
import Features from "./Features";
import StoryTwo from "./StoryTwo";
import StoryThree from "./StoryThree";
import StoryFour from "./StoryFour";
import StoryOne from "./StoryOne";

function Home() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Hero />
      <Social />
      <Box sx={{display:"flex",justifyContent:"center",gap:3,my:5}}>
      <Button  size="large" target="_blank" href="https://dexscreener.com/solana/dplkyiu2khqboavvcglm7prrbqqd7dfzmz2euup51pfj" variant="contained">Dexscreener</Button>
      <Button color="warning" size="large" target="_blank" href="https://www.dextools.io/app/en/solana/pair-explorer/DPLkYiU2KHqboavVCgLM7Prrbqqd7DFZmz2eUUp51Pfj?t=1712519223780" variant="contained">Dextools</Button>
      </Box>
      <Box my={10}>
        <Features />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 5,}}>
        <StoryOne />
        <Typography  textAlign={"center"} variant={isSmallScreen ? 'h6' : 'h3'}>
          One sunny morning, a small kitty named Whiskers was joyfully darting
          through the narrow streets of Kitty Valley. His only concern was playing
          games with his friends and exploring the world around him. However,
          these cheerful days came to an abrupt end with the arrival of the
          dogs' domination.
        </Typography>
        <StoryTwo />
        <Typography textAlign={"center"} variant={isSmallScreen ? 'h6' : 'h3'}>
          With the arrival of the dogs, the tranquility of Kitty Valley was
          shattered into pieces. Whiskers' loved ones, the peace of his
          neighborhood, everything was crushed under the rules of the dogs. The
          little kitty realized that he had no choice but to fight to reclaim
          everything he had lost. He was not only responsible for playing games
          but also for fighting.
        </Typography>
        <StoryThree />
        <Typography textAlign={"center"} variant={isSmallScreen ? 'h6' : 'h3'}>
          Whiskers began to understand that sometimes not only fighting but also
          strategy and diplomacy were crucial. He discovered that working
          together with other kittys was more effective than resisting the power
          of the dogs alone. In this process, he realized that courage and
          strength were not enough; intelligence and planning were equally
          important.
        </Typography>
        <StoryFour />
        <Typography textAlign={"center"} variant={isSmallScreen ? 'h6' : 'h3'}>
          In the end, Whiskers' courage and determination played a leading role
          in the victory against the dogs' domination. He learned a lot in the
          struggle for the freedom of Kitty Valley. He grasped the importance of
          overcoming obstacles, working together, and using the right strategy.
          And these experiences transformed him from just a kitty into a leader.
        </Typography>
      </Box>
      <Tokenomics />
      <Footer />
    </Box>
  );
}

export default Home;
