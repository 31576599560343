import { Box, IconButton, useMediaQuery } from "@mui/material";
import { FaTelegram } from "react-icons/fa";

function Social() {
    const isLgUp = useMediaQuery((theme) => theme.breakpoints.up("lg")); // Check for LG breakpoint

    const social = [
        {
            link:"https://twitter.com",
            icon: "/social/twitter.png"
        },
        {
            link:"https://web.telegram.org/",
            icon: "/social/Tg.svg"
        },
        {
            link:"https://web.telegram.org/",
            icon: "/social/Mask_group.svg"
        },
        {
            link:"https://web.telegram.org/",
            icon: "/social/1.svg"
        },
        {
            link:"https://web.telegram.org/",
            icon: "/social/2.png"
        },
        {
            link:"https://web.telegram.org/",
            icon: "/social/Subtract.png"
        },
        {
            link:"https://web.telegram.org/",
            icon: "/social/phantom.png"
        },        
    ];

    return (
        <Box sx={{display:"flex", alignItems:"center", flexWrap:"wrap",justifyContent:"center",gap:2,mt: isLgUp ? -6 : 0}}>
            {social.map((item, index) => (
                <IconButton sx={{
                    "&:hover": {
                      transform: "scale(1.1)", 
                      transition: "transform 0.2s ease-in-out", 
                    },
                  }} key={index} component="a" href={item.link}>
                    {item.icon ? <img src={item.icon} alt="social icon" /> : null}
                </IconButton>
            ))}
        </Box>
    );
}

export default Social;
