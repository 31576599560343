import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import Header from './Header';
import { useTheme } from '@emotion/react';

function Hero() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
<Box
      sx={{
        backgroundImage: 'url("/heros.jpeg")',
        backgroundSize: 'cover', 
        backgroundPosition: 'top ',
        minHeight: isSmallScreen ? "800px" : "1200px",
        width: '100%',
        display: "flex",
        flexDirection: "column",
        py:2,
        borderRadius:5,
      }}
    >

        <Header/>
       
        <Box sx={{
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }}>
        <Typography sx={{  textShadow: "4px 4px 8px #000000", // gölgenin konumu, bulanıklığı ve rengi
}} textAlign={"center"} variant={isSmallScreen ? 'h5' : 'h3'}>
        In a world filled with puppies and dogbones, you need to ask yourself, Should i show thoose puppies howe a bone is being brought?
        </Typography>
    </Box>
    </Box>

  );
}

export default Hero;
