import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      // main: '#ff7f50',
      main:'#FF7F50'
    },
    background: {
      default: '#bcf001', 
    },
  },
  typography: {
    fontFamily: 'Lilita One, sans-serif', // Genel font ailesi
    title: {
      fontFamily: 'Rubik Scribble, system-ui', // Başlık fontu
    },
    sub: {
      fontFamily: 'Montserrat, sans-serif', // Alt başlık fontu
    },
  },
  shadows: [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.1)',
    '0px 4px 8px rgba(0, 0, 0, 0.1)',
    '0px 8px 16px rgba(0, 0, 0, 0.1)',
    '0px 16px 24px rgba(0, 0, 0, 0.1)',
  ],
});

export default theme;
