import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

function Tokenomics() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
    sx={{
        backgroundImage: 'url("/alloc.jpg")',
        backgroundPosition: 'left',
        backgroundSize:"cover",
        minHeight:isSmallScreen ? "300px" : "836px",
        width: '100%',
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        alignItems:"center",
        gap:5,
        mt:5,
        mb:5
    }}
    >
            <Typography textAlign={"center"} variant={isSmallScreen ? 'h4': 'h2'}>
                Total Supply:<br/>
                88,888,888,888
            </Typography >
            <Typography textAlign={"center"}  variant={isSmallScreen ? 'h4': 'h2'}>
              90% Burned LP  
            </Typography>
            <Typography textAlign={"center"}  variant={isSmallScreen ? 'h4': 'h2'}>
              10% Airdrop<br/>
              to the solana community.
            </Typography>
    </Box>
  )
}

export default Tokenomics