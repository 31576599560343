import { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Box, Button, useTheme, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const menu = [
    {
      name:'Jupiter',
      link:"https://youtube.com"
    },
    {
      name:'Birdeye',
      link:"#2"
    },
    {
      name:'Solscan',
      link:"#3"
    },
    {
      name:'Get Started',
      link:"#1"
    }
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };


  return (
    <Box position={"relative"}>
      <Box sx={{position:"fixed",top:50,right:20}}>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: "#FFFFFF",fontSize:"30px" }} />
      </IconButton>
      </Box>
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={toggleDrawer(false)}
        sx={{ '& .MuiDrawer-paper': { backgroundColor: '#333333',minWidth:"300px" } }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={toggleDrawer(false)} style={{ marginLeft: 'auto' }}>
              <CloseIcon sx={{ color: "#FFFFFF" }} />
            </IconButton>
          </Box>
        <List>
          
          <ListItem sx={{display:"flex",justifyContent:"center"}} >
          <img  style={{width:"100px",height:"100px"}} src="loggow.png"/>
            </ListItem>
            <ListItem sx={{display:"flex",justifyContent:"center",mb:5}} >
            <Typography
              variant='h5'
              color={theme.palette.primary.main}
            >
              Kitty in a puppys world
            </Typography>
            </ListItem>
          {menu.map((item,index) => (
            <ListItem  sx={{display:"flex",justifyContent:"center"}} key={index}>
              <Button 
              href={item.link} 
                variant='text'
                sx={{
                  '&:hover': {
                    color: theme.palette.secondary.main,
                    background:"transparent"
                  },
                  '&:hover .MuiButton-label': {
                    color: theme.palette.secondary.main,
                    background:"transparent"

                  },
                  '&:not(:hover)': {
                    color: theme.palette.primary.main,
                    background:"transparent"

                  },
                  '&:not(:hover) .MuiButton-label': {
                    color: theme.palette.primary.main,
                    background:"transparent"

                  },
                }}
              >
                {item.name}
              </Button>
            </ListItem>
          ))}
          
        </List>
      </Drawer>
    </Box>
  );
}

export default Header;
