import React from 'react';
import { Grid, Box, Typography, useTheme } from '@mui/material';
import ktyhero from "../assets/gamer.jpg";
import bilge from "../assets/bilge.jpg";
import game from "../assets/game.jpg";
import war from "../assets/war.jpg";

// Değişken tanımlaimport game from "../assets/game.jpg";

const featuresData = [
  {
    image: ktyhero,
    title: "Joyful Explorer",
    description: "While joyfully darting through the narrow streets of Kitty Valley, Whiskers never tired of playing games; he constantly sought out new adventures to maintain his excitement and energy",
  },
  {
    image: war,
    title: "Brave Warrior ",
    description: "Under the domination of dogs, Whiskers hesitated not to bravely fight to protect his loved ones."
  },
 
  {
    image: game,
    title: "Smart Diplomat",
    description: "Whiskers discovered the power and strategy of working together with other kittys instead of fighting alone."
  },
  {
    image: bilge,
    title: "Leadership Spark",
    description: "Following victory, Whiskers shone as a pioneering leader, becoming a symbol of the fight for Kitty Valley's freedom."
  },
];

function Features() {
    const theme = useTheme();
  return (
    <Grid container spacing={2} justifyContent="center">
     
      {featuresData.map((feature, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Box 
            sx={{ 
              border: '2px solid #ccc', 
              padding: '20px', 
              borderRadius: '10px', 
              minHeight:"300px",
              transition: 'box-shadow 0.3s', 
              '&:hover': { 
                boxShadow: `0px 0px 10px 5px ${theme.palette.secondary.main}`,              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <Box>
                <img style={{ width: "200px", height: "150px", marginRight: '20px',borderRadius:10 }} src={feature.image} alt={feature.title} />
              </Box>
              <Box>
                <Typography variant="h5">{feature.title}</Typography>
                <Typography sx={{opacity:0.6}} variant="body2">{feature.description}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default Features;
