import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

function StoryFour() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
    sx={{
        backgroundImage: 'url("/zeki.jpeg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight:isSmallScreen ? "500px" : "1600px",
        width: '100%',
        position:"relative",
        border:"1px solid",
        borderRadius:5
    }}
    >
       
    </Box>
  )
}

export default StoryFour