import { Box, Button, IconButton, useTheme } from '@mui/material'
import React from 'react'
import { AiFillTwitterCircle } from "react-icons/ai";

function Footer() {
  const theme = useTheme();
  return (
    <Box sx={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
        <Button size='large' sx={{":hover":{color:theme.palette.secondary.main}}} variant='text' href='#'>
        <span style={{ textDecoration: 'underline' }}>Kitty in a dogs world (MEW)</span>
        </Button>
        <IconButton href='https://twitter.com'>
            <AiFillTwitterCircle color='#FFFFFF' fontSize={"60px"}/>
        </IconButton>
    </Box>
  )
}

export default Footer